.section-front {
   
   &-grid {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-content: flex-start;   
   }
   &-item {
      text-align: left;
      background: $white;
      width: 100%;
      transition: $slow;
      opacity: 0;
      transform: translateY(4rem);
      &.reveal {
        opacity: 1;
         transform: translateY(0);
      }
      @include tablet {
         padding: 0 1rem 2rem;
         margin: 1rem auto;
         max-width: 50%;
      }      
   }
   &-aspect {
      text-decoration: none !important;
      &:hover .section-front-photo-img {
         -moz-transform: scale(1.2);
         -webkit-transform: scale(1.2);
         -o-transform: scale(1.2);
         -ms-transform: scale(1.2);
         transform: scale(1.2);
         opacity: 1.0;
      }
      &:hover .section-front-headline {
         color: $lightblue;
      }
   }
   &-text {
      position: relative;
      top: -5rem;
      z-index: 200;
      background: $white;
      padding: 1rem;
      margin: 1rem 1rem -2rem 1rem;
      text-align: left;
      -webkit-box-shadow: 0 0 10px 0 rgba(0,0,0,.2);
      box-shadow: 0 0 10px 0 rgba(0,0,0,.2);
      @include tablet {
        padding: 1.5rem;
        margin: 1.5rem 1.5rem -3rem 1.5rem;       
        -webkit-box-shadow: 0 0 20px 0 rgba(0,0,0,.2);
        box-shadow: 0 0 20px 0 rgba(0,0,0,.2);
      }
   }
   &-headline {
      font-family: $headline2 !important;
      @extend %headline4;
      color: $orange;
      letter-spacing: -.5px;
      margin-bottom: .25rem;
      transition: $slow;
      text-align: left;
   }
   &-description {
      display: block;
      color: $black !important;
      font-size: 80%;
      line-height: 1.4;
      margin-bottom: .25rem;
   }
   &-photo {
      z-index: 100;
      position: relative;
      width: 100%;
      height: 250px;
      background: $darkgray;
      border-bottom: 8px $orange solid;
      overflow: hidden;
      @include tablet {
        height: 350px;
      }
      @include desktop {
        height: 400px;
      }
      &-img {
         width: 100%;
         height: 100%;
         background-size: cover;
         background-repeat: no-repeat;
         opacity: .5;
         transition: $veryslow;
      }
   }
   
   &-subitem {
     text-decoration: none !important;
     color: $black !important;
     margin: .5rem 0;
     display: block !important;
     line-height: 1.2 !important;
     &-headline {
       display: inline-block;
       position: relative;
       color: $lightblue !important;
       font-weight: 900;
       font-size: 1rem;
       font-family: $nav !important;
       transition: $slow;
       &:after {
         content: "";
         width: calc(100% + .5rem);
         height: 3px;
         position: absolute;
         bottom: -.15rem;
         left: -.25rem;
         z-index: -1;
         background: $lightblue;
         transition: $slow;
       }
     }
     &-description {
       font-size: 1rem;
       display: block;
     }
     &:hover {
       background: none !important;
       & .section-front-subitem-headline {
         color: $orange !important;
       } 
       & .section-front-subitem-headline:after {
         background: $orange;
       }
     }
   }
   
}
