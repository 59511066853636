.find-info {
  padding: 1.5rem 0;
  border-top: 5px white solid;
  @include tablet {
    padding: 2rem 0;
  }  
  & .homepage-layers-item-grid {
    max-width: 100%;
    @include desktop {
      padding: 0 10%;
    }
    @include md {
      padding: 0 10%;
    }
  }
  &-grid {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: flex-start;  
    &-col {
      width: 100%;
      display: grid;
      grid-gap: 1rem;
      @include tablet {
       display: initial;
      }
      @include lg {
        width: auto;
      }
      & .button {
        transform: scale(0.8);
        &:hover {
          background: rgba(255,255,255,.2) !important;
          transform: scale(1);
        }
      }
    }
    &-headline {
      font-family: $headline !important;
      @extend %headline3;
      color: $white;
      letter-spacing: -.5px;
      margin-bottom: 1rem;
      display: block;
      text-align: center;
      font-weight: 900;
      text-transform: uppercase;
      position: relative;
      top: 0;
      @include md {
       font-size: 170%;
      }
      @include lg {
       display: inline-block;
       text-align: left;
       top: 20%;
       margin-bottom: 0;
      }
    }
  }
}
