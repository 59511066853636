.home-featured  {
   &-item {
      background-color: $darkgray;
      width: 100%;
      max-width: 100%; 
      height: auto;
      max-height: auto;
      position: relative;
      margin: 0.5rem auto;
      transition: $slow;
      @include tablet {
         width: 32%;
         height: auto;
         margin: 0 auto;
      }
      @include md {
         width: 100%;
         max-height: 400px;
         max-width: 480px;
         height: 400px;
      }
      &:hover {
         -webkit-box-shadow: 0 0 20px 0 rgba(0,0,0,.3), inset 0 0 0 15px $yellow;
         box-shadow: 0 0 20px 0 rgba(0,0,0,.3), inset 0 0 0 15px $yellow;
      }
      &.gray .home-featured-text-description { color: $black !important; }
      &.white .home-featured-text-description { color: $black !important; }
      &.clear .home-featured-text-description { color: $black !important; }
      &.light-gray .home-featured-text-description { color: $black !important; }
   }
   &-aspect {
      position: relative;
      width:100%;
      height: 100%; 
      display: block;
      text-decoration: none !important;
      overflow: hidden;
      &:hover .home-featured-photo {
         width: calc(100% - 30px);
         height: calc(100% - 30px);
         margin: 15px;
      }
      &:hover .home-featured-photo-img {
         -moz-transform: scale(1.25);
         -webkit-transform: scale(1.25);
         -o-transform: scale(1.25);
         -ms-transform: scale(1.25);
         transform: scale(1.25);
      }
      &:hover .home-featured-text {
      }
   }
   &-text {
      position: relative;
      margin: 0;
      width: 100%;
      top: 50%;
      left: 50%;
      margin-right: -50%;
      transform: translate(-50%, -50%);
      z-index: 200;
      padding: 2rem;
      text-align: center;
      transition: $slow;
      &-headline {
         position: relative;
         display: inline-block;
         font-family: $headline2 !important;
         @extend %headline2;
         letter-spacing: -.5px;
         margin-bottom: 1.25rem;
         transition: $slow;   
         &::after {
            content: "";
            width: 54%;
            height: 8px;
            position: absolute;
            bottom: -8px;
            left: 23%;
            z-index: -1;
            background: $yellow; 
         }
      }
      &-description {
         display: block;
         color: $white !important;
         font-size: 1.2rem;
         line-height: 1.4;
         margin-bottom: 1rem;
      }
   }
   &-photo {
      z-index: 100;
      position: absolute;
      width: 100%;
      height: 100%;
      background: $clear;
      overflow: hidden;
      transition: $slow;
      &-img {
         width: 100%;
         height: 100%;
         background-size: cover;
         background-repeat: no-repeat;
         background-blend-mode: luminosity;
         opacity: .25;
         transition: $veryslow;
      }
   }
}


.home-text-only  {
   &-item {
      position: relative;
      padding: 1rem;
      text-align: center;
      margin: 0 auto;
      width: 100%;
      transition: $slow;
      &-center {
      }
      &-col {
      }
      &-headline {
         @extend %headline4;
         color: $darkgray;
         transition: $slow;
      }
      &-text {
         display: block;
         color: $darkgray;
      }
      & .button.more {
         color: $darkgray;
         &:after {
            background: $darkgray;
            z-index: 1;
         }
      }
      &::after {
         @include tablet-wide {
            content: "";
            width: 1px;
            height: 100%;
            position: absolute;
            right: 0;
            top: 0;
            background: $gray; 
         }
      }
      &:last-child::after {
         display: none;
      }
      &:hover {
         background: $lightgray;
         & .home-text-only-item-headline {
            color: $red;
         }
      }
      @include tablet-wide {
         width: 33.33%;
         padding: 2rem;
      }
   }
}


.home-text-photo  {
   &-item {
      width: 100%;
      text-align: left;
      transition: $slow;
      &:hover {
         -moz-transform: scale(1.1);
         -webkit-transform: scale(1.1);
         -o-transform: scale(1.1);
         -ms-transform: scale(1.1);
         transform: scale(1.1);
      }
      &-center {
         width: 100%;
         height:  100%;
         display: flex;
         flex-wrap: nowrap;
         justify-content: space-between;
         align-content: flex-start;
      }
      &-col {
         &:first-child {
            width: 40%;
         }
         &:last-child {
            margin: 1.5rem;
            width: 60%;
         }
      }
      &-pic {
         width: 100%;
         height: 100%;
         background-size: cover;
         background-position: center center;
         display: block;      
      }
      &-headline {
         @extend %headline4;
      }
      &-text {
         display: block;
         color: $darkgray;
         margin-bottom: .5rem;
      }
      & .button.more {
         color: $yellow;
         left: -.25rem;
         &:after {
            z-index: 1;
         }
      }
      &:first-child {
         margin-bottom: 1rem;
         @include tablet-wide {
            margin-bottom: 0;
         }
      }
      @include tablet {
         width: 60%;
      }
      @include tablet-wide {
         width: 45%;
      }
   }

}


.home-icon  {
   &-item {
      width: 50%;
      transition: $slow;
      padding: 1.5rem;
      &-text {
         position: relative;
         margin: 0;
         width: 100%;
         top: -.5rem;
         z-index: 200;
         padding: 0;
         text-align: center;
         transition: $slow;
         &-headline {
            position: relative;
            display: block;
            font-family: $headline2 !important;
            @extend %headline5;
            margin-bottom: .25rem;
            transition: $slow;
         }
         &-description {
            display: block;
            color: $darkgray;
            font-size: 0.9rem;
            line-height: 1.2;
         }
      }
      &-photo {
         z-index: 1;
         position: relative;
         overflow: hidden;
         transition: $slow;
         &-img {
            width: 100%;
            height: 100%;
            position: absolute;
            text-align: center;
            &-icon {
               position: absolute;
               top: 55%;
               left: 50%;
               margin-right: -50%;
               transform: translate(-50%, -50%);
            }
         }
         &-grad {
            transition: $veryslow;
            margin: 0 auto;
            width: 90px;
            height: 90px;
            border-radius: 50%;
            background: -moz-linear-gradient(top,  rgba(0,0,0,0.2) 0%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(top,  rgba(0,0,0,0.2) 0%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to bottom,  rgba(0,0,0,0.2) 0%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#33000000', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */
         }            
      }
      &:hover {
         -moz-transform: scale(1.1);
         -webkit-transform: scale(1.1);
         -o-transform: scale(1.1);
         -ms-transform: scale(1.1);
         transform: scale(1.1);
         & .home-icon-item-photo-grad {
            background: $yellow !important;
         }
      }
      @include tablet-wide {
         width: 20%;
      }
   }
}


.home-callout {
   &-item {
      width: 100%;
      margin-bottom: 1rem;
      &-text {
         text-align: center;
         &-lg {
            display: block;
            @extend %headlineMega;
            margin-bottom: -.5rem; 
            color: $yellow;
            @include tablet-wide {
               margin-bottom: -1rem;
            }
         }
         &-sm {
            font-family: $headline2 !important;
            @extend %headline3;
            color: $white;
            margin-bottom: 1rem;
         }
      }
      @include tablet-wide {
         width: 30%;
         margin-bottom: 0;
      }
   }
}


.home-graphic {
   &-item {
      transition: $slow;
      width: 80%;
      margin-bottom: 1rem;
      &-figure {
         z-index: 1;
         position: relative;
         overflow: hidden;
         transition: $slow;
         &-img {
            width: 100%;
            height: auto;
            display: block;
            text-align: center;
         }           
      }
      &:hover {
         -webkit-box-shadow: 0 0 20px 0 rgba(0,0,0,.3);
         box-shadow: 0 0 20px 0 rgba(0,0,0,.3);
         -moz-transform: scale(1.1);
         -webkit-transform: scale(1.1);
         -o-transform: scale(1.1);
         -ms-transform: scale(1.1);
         transform: scale(1.1);
      }
      @include tablet {
         width: 30%;
      }
      @include tablet-wide {
         width: 15%;
         margin-bottom: 0;
      }

   }
}


.home-pr  {
   &-item {
      background-color: $darkgray;
      width: 100%;
      height: auto;
      position: relative;
      margin: 0 auto;
      transition: $slow;
      &:hover {
      }
      &.gray .home-pr-text-description { color: $black !important; }
      &.white .home-pr-text-description { color: $black !important; }
      &.clear .home-pr-text-description { color: $black !important; }
      &.light-gray .home-pr-text-description { color: $black !important; }
      @include tablet-wide {
         width: 50%;
         max-width: 50%; 
         margin-bottom: 0;
      }
   }
   &-aspect {
      position: relative;
      width:100%;
      height: 100%; 
      display: block;
      text-decoration: none !important;
      overflow: hidden;

      &:hover .home-pr-photo-img {
         -moz-transform: scale(1.15);
         -webkit-transform: scale(1.15);
         -o-transform: scale(1.15);
         -ms-transform: scale(1.15);
         transform: scale(1.15);
      }
      &:hover .home-pr-text {
      }
   }
   &-text {
      position: relative;
      margin: 0;
      width: 100%;
      top: 50%;
      left: 50%;
      margin-right: -50%;
      transform: translate(-50%, -50%);
      z-index: 200;
      padding: 4rem;
      text-align: center;
      transition: $slow;
      &-preheadline {
      	background: none;
   		color: $white;
   		font-family: $nav;
         font-size: 1.1rem;
   		display: inline-block;
   		margin: 0 auto;
   		text-transform: uppercase;
   		padding: .2rem .4rem;
   		margin-bottom: 0.75rem;
   		opacity: 1;
   		position: relative;
   		z-index: 200;
         left: -.4rem;
         @extend %headline5;	  
         &::after {
            content: "";
            width: 50%;
            height: 3px;
            position: absolute;
            bottom: -2px;
            left: 25%;
            z-index: -1;
            background: $yellow; 
         }	  
   	}
      &-headline {
         position: relative;
         display: inline-block;
         font-family: $headline2 !important;
         @extend %headline4;
         color: $white;
         letter-spacing: -.5px;
         margin-bottom: 1.25rem;
         transition: $slow;
      }
   }
   &-photo {
      z-index: 100;
      position: absolute;
      width: 100%;
      height: 100%;
      background: $clear;
      overflow: hidden;
      transition: $slow;
      &-img {
         width: 100%;
         height: 100%;
         background-size: cover;
         background-repeat: no-repeat;
         background-blend-mode: luminosity;
         opacity: .2;
         transition: $veryslow;
      }
   }
}


.home-newsletter  {
   &-item {
      background-color: $darkgray;
      width: 100%;
      height: auto;
      position: relative;
      margin: 0 auto;
      transition: $slow;
      &:hover {
      }
      &.gray .home-pr-text-description { color: $black !important; }
      &.white .home-pr-text-description { color: $black !important; }
      &.clear .home-pr-text-description { color: $black !important; }
      &.light-gray .home-pr-text-description { color: $black !important; }
      @include tablet-wide {
         width: 50%;
         max-width: 50%; 
         margin-bottom: 0;
      }
   }
   &-aspect {
      position: relative;
      width:100%;
      height: 100%; 
      display: block;
      text-decoration: none !important;
      overflow: hidden;

      &:hover .home-newsletter-photo-img {
         -moz-transform: scale(1.15);
         -webkit-transform: scale(1.15);
         -o-transform: scale(1.15);
         -ms-transform: scale(1.15);
         transform: scale(1.15);
      }
      &:hover .home-pr-text {
      }
   }
   &-text {
      position: relative;
      margin: 0;
      width: 100%;
      top: 50%;
      left: 50%;
      margin-right: -50%;
      transform: translate(-50%, -50%);
      z-index: 200;
      padding: 4rem;
      text-align: center;
      transition: $slow;
      &-headline {
         position: relative;
         display: block;
         font-family: $headline2 !important;
         @extend %headline3;
         color: $darkgray;
         letter-spacing: -.5px;
         margin-bottom: 1.25rem;
         transition: $slow;
      }
   }
   &-photo {
      z-index: 100;
      position: absolute;
      width: 100%;
      height: 100%;
      background: $clear;
      overflow: hidden;
      transition: $slow;
      &-img {
         width: 100%;
         height: 100%;
         background-size: cover;
         background-repeat: no-repeat;
         background-blend-mode: luminosity;
         opacity: .2;
         transition: $veryslow;
      }
   }
}
